<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table nowrap">
                <thead>
                    <tr>
                        <th>Invoice no</th>
                        <th>Invoice Date</th>
                        <th>Bill Type</th>
                        <th>Consultant</th>
                        <th>Created By</th>
                        <th>Patient ID</th>
                        <th>Patient Name</th>
                        <th>Patient Mobile No</th>
                        <th>Unit</th>
                        <th>Admission No</th>
                        <th class="text-right">Amount</th>
                        <th class="text-right">VAT Amount</th>
                        <th class="text-right">Total Sales Amount</th>
                        <th class="text-right">Settlement Dis</th>
                        <th class="text-right">Net Bill Amount</th>
                        <th class="text-right">Paid Amount</th>
                        <th class="text-right">Return Amount</th>
                        <th class="text-right">Net Collection</th>
                        <th class="text-right">Due Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(child, childIndex) in props.sales" :key="childIndex">
                        <th>{{ child.bill_number }}</th>
                        <th>{{ child.date }}</th>
                        <th>{{ child.invoice_type }}</th>
                        <th>{{ child.service_resource && child.service_resource.name }}</th>
                        <th>{{ child.created_user.name }}</th>
                        <th>{{ child.contact.patient_id }}</th>
                        <th>{{ child.contact.full_name }}</th>
                        <th>{{ child.contact.mobile_no }}</th>
                        <th></th>
                        <th></th>
                        <th class="text-right">{{ commaFormat(child.subtotal_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.vat_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.subtotal_amount + child.vat_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.total_discount) }}</th>
                        <th class="text-right">{{ commaFormat(child.subtotal_amount + child.vat_amount - child.total_discount) }}</th>
                        <th class="text-right">{{ commaFormat(child.paid_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.sale_return_masters_sum_paid_amount) }}</th>
                        <th class="text-right">{{ commaFormat(child.paid_amount-child.sale_return_masters_sum_paid_amount) }}</th>
                        <th class="text-right">{{ commaFormat((child.subtotal_amount + child.vat_amount - child.total_discount - child.paid_amount)) }}</th>
                    </tr>
                    <tr>
                        <th colspan="10" class="text-right">Total ({{ props.sales.length }})</th>
                        <th class="text-right">{{ commaFormat(getTotalAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalVat(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalSalesAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalDiscount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalNetBillAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalPaidAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalReturnAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalPaidAmount(props.sales)-getTotalReturnAmount(props.sales)) }}</th>
                        <th class="text-right">{{ commaFormat(getTotalNetBillAmount(props.sales) - getTotalPaidAmount(props.sales)) }}</th>
                    </tr>
                </tbody>
            </table>
          <p v-if="!props.sales.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";

const props = defineProps({
    sales: Array
})

const { commaFormat } = figureFormatter();

const getTotalAmount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.subtotal_amount, 0);
}

const getTotalVat = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.vat_amount, 0);
}

const getTotalSalesAmount = (item) => {
    return item.reduce((total, saleMaster) => total + (saleMaster.subtotal_amount + saleMaster.vat_amount), 0);
}

const getTotalDiscount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.total_discount, 0);
}

const getTotalNetBillAmount = (item) => {
    return item.reduce((total, saleMaster) => total + (saleMaster.subtotal_amount + saleMaster.vat_amount - saleMaster.total_discount), 0);
}

const getTotalPaidAmount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.paid_amount, 0);
}
const getTotalReturnAmount = (item) => {
    return item.reduce((total, saleMaster) => total + saleMaster.sale_return_masters_sum_paid_amount, 0);
}
</script>

<style scoped>
    .nowrap{
        white-space: nowrap;
    }
</style>
